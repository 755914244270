/*
  This variable will be available everywhere,
  because every element is a descendant of
  the HTML tag:
*/
html {
    --color-black: #000004;
    --color-white: #e4fbff;

    --color-grey4: #4f4f4f;
    --color-grey3: #292c33;
    --color-grey2: #424a53;
    --color-grey1: #646d77;

    --color-white4: #87a6ac;
    --color-white3: #add0d7;
    --color-white2: #cdeaef;
    --color-white1: #e4fbff;

    /* --color-bg: #fff78c;
    --color-turq: #00c7bf;
    --color-green: #b3de00; */

    /* --color-bg: #e8ff92;
    --color-bg-dark: #d4ee74;
    --color-turq: #00c2e6;
    --color-green: #a278f1; */

    --color-bg: #93efeb;
    --color-bg-dark: #78e6e1;
    --color-turq: #00c2e6;
    --color-green: #a278f1;

  }