@import "./colors.css";

.Collapsible__trigger {
    width: 100%;
}

.Collapsible {
    /* background-color: red; */
    width: 100%;
    /* display: flex;
    justify-content: space-between; */
    
}

.Collapsible__contentInner {
    /* background-color: yellow; */
    padding: 0 16px;
}