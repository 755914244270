@import "./colors.css";


.topImage {
    min-width: 100%;
    min-height: 300px;
    position: absolute;
    top: 0;
    left: 0;
    image-rendering: pixelated;
    z-index: 1;
}

.bottomImage {
    min-width: 100%;
    min-height: 300px;
    position: absolute;
    left: 0;
    bottom: 0;
    image-rendering: pixelated;
    z-index: 1;
}

.pageContainer {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 100%;
    background-color: var(--color-bg);
}

.bodyContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
}

.footerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    background-color: var(--color-bg-dark);
}

.navigationContainerRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    text-align: right;

    z-index: 20;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;

    box-sizing: border-box;
    transition: all 0.4s;
    height: 100%;
}

.navigationContainerDesktop {
    padding: 16px;
}

.navigationIcon {
    height: 50px;
    width: 50px;
    padding: 4px;
    opacity: 1;
    transition: all 0.4s;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: pixelated;
    image-rendering: crisp-edges;
}

.navigationText {
    font-family: 'Silkscreen', cursive;
    font-weight: 400;
    opacity: 1;
    font-size: 18pt;
    font-style: normal;
    transition: all 0.4s;
    text-align: right;
}

.hamburgerIcon {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}


.menuContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 16px;
}

.mobileMenuContainer {
    height: 100%;
}
.mobileMenuContainerInner {
    padding: 16px;
    display: flex;
    flex-direction: column;
    width: 200px;
    justify-content: flex-end;
    align-items: flex-end;
}

.mobileMenuContainerBackground {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    bottom: 0;
    background-color: #85b3c6;
}

.navigationIcon:hover, .navigationText:hover {
    opacity: 0.5;
    cursor: pointer;
}

.header1 {
    font-family: 'Silkscreen', cursive;
    font-weight: 400;
    font-size: 83px;
    width: 100%;
}

.headerSmall {
    font-family: 'Silkscreen', cursive;
    font-weight: 400;
    font-size: 23px;
    margin: 0;
    padding: 0;
    padding-bottom: 8px;
    padding-top: 32px;
}

.headerTiny {
    font-family: 'Silkscreen', cursive;
    font-weight: 400;
    font-size: 15px;
    margin: 0;
    padding: 0;
}


/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 768px) {
    .header1 {
        font-size: 67px;
    }

    .navigationIcon {
        height: 100px;
        width: 100px;
    }

    .navigationText {
        font-size: 30pt;
    }
    
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}