@import "../ui/colors.css";

.homeHeader {
    color: var(--color-turq);
}

.topPadding {
    height: 320px;
}

.sectionGridContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
}

.sectionGridContainerInner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 16px;
}

.sectionGridImage {
    width: 100%;
}

.sectionImage {
    width: 40%;
    image-rendering: pixelated;
}

.sectionImageShort {
    max-height: 70px;
}

.sectionImageSmall {
    width: 20%;
}

.sectionImageTall {
    width: 70%;
    image-rendering: pixelated;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.smallIcon {
    height: 16px;
    width: 16px;
    padding-right: 12px;
    opacity: 1;
}

.smallIcon:hover {
    opacity: 0.5;
    cursor: pointer;
}

.iconLink {
    display: flex;
    align-items: center;
}

html {
    --gridWidth: 150px;
}

.gridTitle {
    position: absolute;
    top: var(--gridWidth);
    left: calc(var(--gridWidth) * 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.gridTitleRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.gridTitleCard {
    font-size: 70px;
    font-family: 'Silkscreen', cursive;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.grid {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.gridImages {
    width: var(--gridWidth);
    height: var(--gridWidth);
}

.redacted {
    /* color: var(--color-bg-dark); */
    letter-spacing: -1px;
    overflow-wrap: anywhere;
}

.sectionDropdownInner {
    width: 100%;
    padding: 8px;
    padding-top: 0;
    padding-bottom: 16px;
}

/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 768px) {
    .sectionGridContainer {
        flex-direction: column;
    }
    .sectionGridImage, .sectionImageSmall {
        width: 50%;
    }

    /* .grid {
        width: 600px;
        height: 800px;
    } */

.sectionImageTall {
    width: 30%;
}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    /* .grid {
        width: 100%;
        height: 400px;
    } */
}