/* @import url("https://use.typekit.net/zmm8jpe.css"); */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Silkscreen:wght@400;700&display=swap');

/* 
FONTS

font-family: adobe-caslon-pro,serif;
font-weight: 400;
font-style: normal;

font-family: adobe-caslon-pro,serif;
font-weight: 400;
font-style: italic;

font-family: adobe-caslon-pro,serif;
font-weight: 700;
font-style: normal;

font-family: adobe-caslon-pro,serif;
font-weight: 700;
font-style: italic;

font-family: adobe-caslon-pro,serif;
font-weight: 600;
font-style: normal;

font-family: adobe-caslon-pro,serif;
font-weight: 600;
font-style: italic;


font-family: bio-sans,sans-serif;
font-weight: 400;
font-style: italic;

font-family: bio-sans,sans-serif;
font-weight: 400;
font-style: normal;

font-family: bio-sans,sans-serif;
font-weight: 600;
font-style: normal;

font-family: bio-sans,sans-serif;
font-weight: 700;
font-style: italic;

font-family: bio-sans,sans-serif;
font-weight: 700;
font-style: normal;

font-family: bio-sans,sans-serif;
font-weight: 200;
font-style: normal;

font-family: bio-sans,sans-serif;
font-weight: 200;
font-style: italic;

font-family: bio-sans,sans-serif;
font-weight: 300;
font-style: normal;

font-family: bio-sans,sans-serif;
font-weight: 300;
font-style: italic;

font-family: antiquarian-scribe, sans-serif;
font-weight: 400;
font-style: normal;







font-family: 'Open Sans', sans-serif;
font-family: 'Silkscreen', cursive;

*/


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

html, body, #root {
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 1px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  background-color: var(--color-white1);
  color: var(--color-grey4);
}

a {
  color: var(--color-grey4);
  text-decoration: none;
  font-family: 'Silkscreen', cursive;
}

a:hover {
  color: var(--color-grey1);
  text-decoration: none;
}

h1, h2, h3, h4 {
    font-family: 'Silkscreen', cursive;
    font-weight: 400;
    /* font-size: 110px; */
}